.inverted-colors {
  mix-blend-mode: multiply;
  --mapfilter: grayscale(1);
  filter: var(--mapfilter);
  -webkit-filter: var(--mapfilter);
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.custom-scrollbar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.fade-out {
  animation: fadeOutDelay 2s forwards;
  animation-delay: 1s;
}

@keyframes fadeOutDelay {
  0% {
      opacity: 0.9;
  }
  45% {
      opacity: 0.9;
  }
  100% {
      opacity: 0;
  }
}
