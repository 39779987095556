.skeleton-pulse {
    background: linear-gradient(90deg, #ffffff00 0%, #ffffff00 40%, #f0f0f06a 50%, #ffffff00 60%, #ffffff00 100%);
    background-size: 200% 100%;
    animation: pulse 1.5s infinite linear;
  }
  
  @keyframes pulse {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  