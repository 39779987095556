.box-pulse {
    background: linear-gradient(90deg, #087cb4 0%, #087cb4 45%, #4e88a5 50%, #087cb4 55%, #087cb4 100%);
    background-size: 200% 100%;
    animation: pulse 1.5s infinite linear;
  }
  
  @keyframes pulse {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  