.slick-next{
    right: 3px;
}

.slick-next:before{
    color: lightgray;
}

.slick-prev{
    left: 3px;
}

.slick-prev:before{
    color: lightgray;
}